<template>
    <div class="index">
        <headers :headIndex="4"></headers>
        <top-img></top-img>
        <div class="teachers">
            <div class="teachers-content">
                <!-- 头部导航栏 -->
                <div class="top-nav">
                    <div class="nav-item" v-for="(item, index) in navList" :key="index" @click="changNav(index, $event)" :class="navIndex == index ? 'nav-item-check' : ''">{{item}}</div>
                    <div class="top-nav-line" :style="{left: left+'px'}"></div>
                </div>
                
                <!-- 成人师资 -->
                <div class="list">
                    <div class="item" v-for="(item, index) in list" :key="index" @click="navto(item)">
                        <div class="item-img">
                            <img :src="item.image" class=""/>
                        </div>
                        <div class="item-info">
                            <div class="item-info-title"> {{ item.title }}</div>
                            <p> {{ item.describe }}</p>
                        </div>
                    </div>
                </div>
                <!-- 分页 -->
                <paging @changeIndex="changeIndex" :allNum="total"></paging>
            </div>
        </div>
        <footers></footers>
    </div>
</template>
<script>
import headers from '@/components/headers'
import footers from '@/components/footers'
import topImg from '@/components/topImg'
import paging from '@/components/paging'
export default {
    name: 'teachers',
    components:{
        headers,
        footers,
        topImg,
        paging
    },
    data() {
        return {
            navList: ['成人师资', '童艺师资'],
            navIndex: 0,
            left: 74,
            list:[],
            page:1,//页
            limit:6,//条
            total:0,//总页数
            count:0,//总条数
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList(){
            if(this.navIndex==0){//成人师资
                this.$request({
                    url:'/api/article/teacher1',
                    method:'POST',
                    data:{
                        page:this.page,
                        limit:this.limit
                    }
                }).then(res=>{
                    if(res.code==1){
                        console.log(res,'成人师资')
                        this.list=[]
                        this.list=res.data.list
                        this.count=res.data.count
                        this.total=res.data.total
                    }
                })
            }else if(this.navIndex==1){//童艺师资
                this.$request({
                    url:'/api/article/teacher2',
                    method:'POST',
                    data:{
                        page:this.page,
                        limit:this.limit
                    }
                }).then(res=>{
                    if(res.code==1){
                        console.log(res,'童艺师资')
                        this.list=[]
                        this.list=res.data.list
                        this.count=res.data.count
                        this.total=res.data.total
                    }
                })
            }
        },
        changNav(index, e) {
            console.log(e)
            this.navIndex = index
            this.left = 74+(146*index)
            this.getList()
        },
        navto(item) {
            this.$router.push({
                path:"/society/elegantDetail",
                query:{
                    id:item.id
                }
            })
        },
        changeIndex(e){
            console.log(e) 
            this.page=e
            this.getList()
        },
    }
}
</script>
<style lang="scss" scoped>
    .index{
        width: 100vw;
    }
    .teachers{
        width: 100%;
        display: flex;
        justify-content: center;
        background: #F6F6EA;
    }
    .teachers-content{
        width: 1200px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        padding: 60px 0;
    }
    // 头部导航栏
    .top-nav{
        padding-bottom: 23px;
        border-bottom: 1px solid #CCCCCC;
        display: flex;
        position: relative;
    }
    .top-nav-line{
        width: 49px;
        height: 2px;
        background: #26716C;
        border-radius: 1px;
        position: absolute;
        bottom: 0;
        transition: all 1s;
    }
    .nav-item{
        padding-left: 50px;
        color: #333333;
        font-size: 24px;
        cursor: pointer;
    }
    .nav-item-check{
        color: #26716C;
        font-weight: 550;
    }

    // 成人
    .list{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 50px;
        margin-bottom: 50px;
    }
    .item{
        display: flex;
        box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
        padding: 36px;
        margin-top: 78px;
    }
    .item-img{
        width: 193px;
        height: 239px;
        margin-top: -80px;
        img{
            width: 193px;
            height: 239px;
            // object-fit:fill;
        }
        
    }
    .item-info{
        padding-left: 70px;
    }
    .item-info-title{
        color: #313131;
        font-size: 26px;
        font-weight: bold;
    }
    .item-info p{
        text-indent: 2em;
        color: #707070;
        padding-top: 20px;
        height: 125px;
        text-overflow:ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
    }
    .item:hover{
        cursor: pointer;
        background: #26716C;
        color: #ffffff;
    }
    .item:hover .item-info-title, .item:hover .item-info p{
        color: #ffffff; 
    }
</style>